import HeaderLogo from './header-logo.svg';

//import partnerHeaderLogo from './staff-portal-header.png';
import { PermissionsApiUrl, PushRegisterApiUrl } from './components/apiUrls';
import { PortalRouterPageObj } from './stdlib/stdlib-appmodels';
import React from 'react';
import { stdlibApp } from './stdlib/stdlib-app';
//import { FtPortalVersion } from './staff-partner-config';

const DashPage = React.lazy(() => import('./pages/DashPage'));
const NewBookingBouncePage = React.lazy(() => import('./pages/NewBookingBouncePage'));
const ScanContainerPage = React.lazy(() => import('./pages/scan/ScanContainerPage'));
const FlightsContainerPage = React.lazy(() => import('./pages/FlightsPage'));
const DateStatusCalendarPage = React.lazy(() => import('./pages/DateStatusCalendarPage'));
const ConfigurationContainerPage = React.lazy(() => import('./pages/configuration/ConfigurationContainerPage'));
const CustomerPage = React.lazy(() => import('./pages/customer/CustomerRoutesPage'));
const DispatcherPage = React.lazy(() => import('./pages/dispatcher/DispatcherPage'));
const DriverDashboardPage = React.lazy(() => import('./pages/driver/dashboard/DriverDashboardPage'));
const DriverTransportReportPage = React.lazy(() => import('./pages/driver/DriverTransportReportPage'));
const DriverGratuityReportPage = React.lazy(() => import('./pages/driver/DriverGratuityPage'));
const FastTrackConcerigePage = React.lazy(() => import('./pages/FastTrackConcerigePage'));
const DailyReportContainerPage = React.lazy(() => import('./pages/dailyreport/DailyReportContainerPage'));
const ReportsContainerPage = React.lazy(() => import('./pages/reports/ReportsContainerPage'));
const PartnerContainer = React.lazy(() => import('./pages/partner/PartnerContainerPage'));
const PartnerManagerContainerPage = React.lazy(() => import('./pages/partner-manager/PartnerManagerContainerPage'));
const ImportContainerPage = React.lazy(() => import('./pages/import/ImportContainerPage'));
const SignOutPage = React.lazy(() => import('./pages/SignOutPage'));

//{ path: '/', exact: true, name: 'Blank page', component: DashPage, shouldCloseMenu: true },

export const FtPortalVersion = import.meta.env.VITE_PORTAL_TYPE;

export const routes: PortalRouterPageObj<StaffPartnerPermissions>[] = [


  { path: '/', exact: true, name: 'Dashboard', component: DashPage, shouldCloseMenu: true },
  { path: '/new-booking', exact: true, name: 'New Booking', component: NewBookingBouncePage, shouldCloseMenu: true },
  { path: '/customers', name: 'Bookings', component: CustomerPage, shouldCloseMenu: true },
  { path: '/partner', exact: false, name: 'Partners', component: PartnerContainer, shouldCloseMenu: false },
  { path: '/driver-dash', exact: true, name: 'Driver Dashboard', component: DriverDashboardPage, shouldCloseMenu: true },
  { path: '/driver-trips', exact: true, name: 'Trip Report', component: DriverTransportReportPage, shouldCloseMenu: true },
  { path: '/driver-gratutities', exact: true, name: 'Gratuties Report', component: DriverGratuityReportPage, shouldCloseMenu: true },
  { path: '/scan', name: 'FT Scan', component: ScanContainerPage },
  { path: '/concerige-dash', name: 'FT Concerige', component: FastTrackConcerigePage, shouldCloseMenu: true },
  { path: '/dispatcher', name: 'Dispatcher', component: DispatcherPage, shouldCloseMenu: true },
  
  { path: '/flights', name: 'Flights', component: FlightsContainerPage, shouldCloseMenu: false },
  { path: '/date-status', name: 'Date Status', component: DateStatusCalendarPage, shouldCloseMenu: true },
  { path: '/import', name: 'Import Customers', component: ImportContainerPage, shouldCloseMenu: false },

  { path: '/daily-reports', name: 'Daily Reports', component: DailyReportContainerPage, shouldCloseMenu: false },
  { path: '/reports', name: 'Reports', component: ReportsContainerPage, shouldCloseMenu: false },

  { path: '/manager', name: 'Manager', component: PartnerManagerContainerPage, shouldCloseMenu: false },

  { path: '/configuration', name: 'Configuration', component: ConfigurationContainerPage, shouldCloseMenu: false },
  { path: '/sign-out', name: 'Sign Out', component: SignOutPage, shouldCloseMenu: true },
];

export interface StaffPartnerPermissions {
  name: string;
  hasViewCustomerPermission: boolean;
  hasEditCustomerPermission: boolean;
  hasResetBookingPermission: boolean;
  hasLedgerChargePermission: boolean;
  hasFinancialPermission: boolean;

  hasStaffSeeCustomerPricesPermission: boolean;
  hasStaffDispatcherPermission: boolean;
  hasStaffFastTrackPermission: boolean;
  hasStaffResetFastTrackPermission: boolean;
  hasStaffDriverPermission: boolean;
  hasStaffAdminSystemPermission: boolean;
  hasStaffManagePartnerPermission: boolean;
}

const routeFilterFunc = (appData: StaffPartnerPermissions, route: PortalRouterPageObj<StaffPartnerPermissions, {}, {}>) => {

  //if (appData.hasStaffAdminSystemPermission) return true;
  if (route.component === DashPage) return true;
  if (route.component === FlightsContainerPage) return true;
  if (route.component === SignOutPage) return true;

  if (FtPortalVersion === "staff") {

    if (route.component === ScanContainerPage) return appData.hasStaffFastTrackPermission;
    if (route.component === FastTrackConcerigePage) return appData.hasStaffFastTrackPermission;
    if (route.component === DateStatusCalendarPage) return appData.hasEditCustomerPermission;
    if (route.component === ConfigurationContainerPage) return appData.hasStaffAdminSystemPermission;
    if (route.component === DispatcherPage) return appData.hasStaffDispatcherPermission;
    if (route.component === DriverDashboardPage) return appData.hasStaffDriverPermission;
    if (route.component === DriverTransportReportPage) return appData.hasStaffDriverPermission;
    if (route.component === DriverGratuityReportPage) return appData.hasStaffDriverPermission;
    if (route.component === DailyReportContainerPage) return appData.hasEditCustomerPermission;
    if (route.component === ReportsContainerPage) return appData.hasFinancialPermission;
    if (route.component === PartnerContainer) return appData.hasStaffManagePartnerPermission;

    if (route.component === ImportContainerPage) return appData.hasStaffAdminSystemPermission;

  }

  if (FtPortalVersion === "partner") {
    if (route.component === PartnerManagerContainerPage) return appData.hasFinancialPermission;
  }

  if (route.component === CustomerPage) return appData.hasViewCustomerPermission;
  if (route.component === NewBookingBouncePage) return appData.hasEditCustomerPermission;

  return false;
}

interface AppReturn {
  headerLogo: string;
  authBackgroundUrl: string;
  authLogoUrl: string;
  defaultTitle: string;
}

const staticAppProps: AppReturn = FtPortalVersion === "staff" ? {
  authBackgroundUrl: "/staff-auth-background.jpg",
  authLogoUrl: "/logo192.png",
  defaultTitle: "Fast Track® Staff Portal",
  headerLogo: HeaderLogo,


} : {
  authBackgroundUrl: "/partner-auth-background.jpg",
  authLogoUrl: "/logo192.png",
  defaultTitle: "Fast Track® Partner Portal",
  headerLogo: HeaderLogo,

};

export default stdlibApp<StaffPartnerPermissions>({
  appDataApiUrl: PermissionsApiUrl,
  routes,
  routeFilterFunc,
  footerCompany: "Riders Ltd",
  registerForPush: FtPortalVersion === "staff",
  pushKeyUrl: "/push-key.txt",
  pushRegisterApiUrl: PushRegisterApiUrl,

  ...staticAppProps,

})