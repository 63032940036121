import { useEffect, useState } from "react";
import { PageState, PageStatus, bannerHelper, callGetApi, callPostApi, formRows } from "../stdlib/stdlib-form";
import { Modal } from "../stdlib/stdlib-modals";
import { useForm } from "react-hook-form";
import { EditCommentsApi } from "./apiUrls";

export interface BookingCommentsModel {
    comments: string;
}

export interface BookingModalProps {
    //open: boolean;
    bookingGuid: string | undefined;
    openCount: number;
    onClose: (refreshNeeded: boolean) => void;
}

export const BookingCommentsModal = (props: BookingModalProps) => {

    const [state, setState] = useState<PageState>(PageState.NotLoaded);
    const [status, setStatus] = useState<PageStatus>(PageStatus.Idle);
    const formObj = useForm<BookingCommentsModel>();
    const working = status === PageStatus.Network;
    const { TextAreaRow } = formRows(formObj, working);
    const { statusBanner, contentNotLoaded } = bannerHelper(state, status);


    useEffect(() => {
        if (!props.bookingGuid) return;
        callGetApi(EditCommentsApi(props.bookingGuid), setState, setStatus, (data: BookingCommentsModel) => {
            formObj.reset(data);
        });
    }, [props.bookingGuid]);

    const doSubmit = (data: BookingCommentsModel) => {
        callPostApi(EditCommentsApi(props.bookingGuid!), data, setState, setStatus, formObj.setError as any, () => props.onClose(true));
    }

    const notLoaded = contentNotLoaded();
    return <Modal
        key={props.openCount}
        open={!!props.bookingGuid}
        title="Booking Comments"
        okText="Save"
        okClick={() => formObj.handleSubmit(doSubmit)()}
        cancelClick={() => props.onClose(false)}
        cancelText="Close"
    >
        <div className="flex flex-col flex-1">
            {statusBanner()}
            {!notLoaded && TextAreaRow({ title: "Comments", property: "comments" })}
        </div>
    </Modal>;
};