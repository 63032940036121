import { FtPortalVersion, StaffPartnerPermissions } from "src/App";
import { DateToHtmlString } from "src/pages/dailyreport/DailyReportShared";
import { showOverride } from "./misc-functions";

export const NowAsHtmlDate = () => {
    //var now = new Date();
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const day = today.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
    //return `${now.getFullYear()}-${now.getMonth() < 10 ? "0" : ""}${now.getMonth() + 1}-${now.getDate() < 10 ? "0" : "" }${now.getDate()}`;
}

export const OneMonthAgoAsHtmlDate = () => {
    var now = new Date();
    now.setMonth(now.getMonth() - 1);
    return `${now.getFullYear()}-${now.getMonth() < 10 ? "0" : ""}${now.getMonth() + 1}-${now.getDate() < 10 ? "0" : ""}${now.getDate()}`;
}
//todo move to stdlib

export const DatePickerMin : (permissions: StaffPartnerPermissions)=>{allowSameDate: boolean, defaultDate: Date} = (permissions) => {
    const allowSameDate=showOverride(permissions);

    const defaultDate = MinDate(allowSameDate);
    const defaultDateStr= DateToHtmlString(defaultDate);
    return ({allowSameDate, defaultDate,defaultDateStr});

}

export const MinDate= (allowToday: boolean)=>{
    const minDate = new Date();
    if (!allowToday) {
        minDate.setDate(minDate.getDate() + 1);
    }
    return minDate;

}