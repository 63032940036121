/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-explicit-any */

// export interface GridTableColumn<T>{
//     fieldName: keyof T,
// }

export interface GridTableCell{
    title: string,    
    rowClass?: string
}

export interface GridTableSimpleCell<T> extends GridTableCell {
    property: keyof T,
}

export interface GridTableComplexCell<T> extends GridTableCell {
    cell: (data: T) => JSX.Element[] | JSX.Element,
}


export type GridCell<T> = GridTableSimpleCell<T> | GridTableComplexCell<T>;

export interface GridTableProps<T> {
    //tableHeaders: string[],
    headerCellClass?: string,
    tableStyle: React.CSSProperties
    tableClass?: string
    rowClass?: string,
    highlightRow?: (row: T) => boolean,
    highlightRowClass?: string,
    //data: T[] | undefined,
    tableColumns: GridCell<T>[]
    //drawRow: (data: T[], index: number) => JSX.Element[],
 
}


export const GridTable = <T,>(props: GridTableProps<T> & { data: T[] | undefined }) => {
    

    const drawCell = (row: number, column: number, lastCell: boolean, lastRow: boolean, cell: GridTableSimpleCell<T> | GridTableComplexCell<T>) => {
        
        const dataItem = (props.data as T[])[row] as T;

        const renderCellContent = () => {

            

            if (cell.hasOwnProperty("cell")) {
                return (cell as GridTableComplexCell<T>).cell(dataItem)
            }

            if (cell.hasOwnProperty("property")) {
                const c = (cell as GridTableSimpleCell<T>);

                return <span>{(dataItem as any)[c.property]} </span>;
            }

            return <></>;
        }

        const highlight = props.highlightRow ? props.highlightRow(dataItem) : false;

        const borderCss = lastCell ? "border-t border-l border-r" : "border-t border-l";

        const lastRowCss = lastRow ? "border-b" : "";

        return <div key={"r" + row + "c" + column} className={`${highlight ? props.highlightRowClass : props.rowClass} ${cell.rowClass} p-1 ${borderCss} ${lastRowCss}`}>
            {props.data && renderCellContent()}
        </div>;


        



    }

    const columnCount = props.tableColumns?.length - 1 ?? 0;
    const rowCount = props.data?.length  ? props.data.length - 1  : 0;
    
    return <div className="p-4 bg-white flex flex-1 min-h-0"> <div className="flex flex-1 overflow-y-scroll"><div className={`grid self-start flex-1  ${props.tableClass}`} style={props.tableStyle}>
        {props.tableColumns && props.tableColumns.map((x, i) =>
            <div key={"header" + i} className={`bg-white sticky top-0 ${props.headerCellClass} font-semibold ${i === columnCount ? "border-l-2 border-r-2 border-t-2 border-b-2"
                : "border-t-2 border-l-2 border-b-2" }  p-1`}>
                {x.title}
            </div>)}
        {props.data && props.data.map((_, row) => props.tableColumns.map((cell,column) => drawCell(row,column,column === columnCount,row === rowCount,cell)))}
    </div></div> </div>;

}
