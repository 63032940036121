/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, ReactElement, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import { bannerHelper, LoadingAnimation, PageState, PageStatus, useErrorStore, callPostApi, formRows, copyNewErrorsToOldForm } from './stdlib-form';
import { useForm } from 'react-hook-form';
import { DeleteModel, GuidResponseModel } from './stdlib-models';
import { TitleLabelClass } from "./stdlib-styles";

export interface ModalProps {
    open: boolean;
    title: string,
    okHidden?: boolean,
    cancelHidden?: boolean,
    okText: string,
    okButtonFormId?: string,
    okClick?: () => void,
    okDisabled?: boolean,
    cancelText?: string,
    cancelDisabled?: boolean,
    cancelClick?: () => void,
    iconVisible?: boolean,
    working?: boolean,
    children?: ReactElement<any, any>[] | ReactElement<any, any> | string
}

export const Modal: (props: ModalProps) => JSX.Element = (props) => {
    const refDiv = useRef(null)
    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                //initialFocus={cancelButtonRef}
                initialFocus={refDiv}
                open={props.open}
                onClose={() => { }}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">

                                    {props.iconVisible &&
                                        <div className="hidden mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                        </div>
                                    }
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-1">
                                        <Dialog.Title as="h3" className={TitleLabelClass}>
                                            {props.title}
                                        </Dialog.Title>
                                        <div className="mt-2" ref={refDiv}>
                                            {props.children}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-50 px-4 py-3 sm:px-6 gap-4 flex flex-col md:flex-row md:justify-end">
                                {props.working && <LoadingAnimation />}
                                {!props.okHidden &&
                                    <button
                                        type={props.okButtonFormId ? "submit" : "button"}
                                        disabled={props.working || props.okDisabled}
                                        form={props.okButtonFormId}
                                        className="btn btn-primary"
                                        onClick={props.okClick}
                                    >
                                        {props.okText ?? "Ok"}
                                    </button>}
                                {!props.cancelHidden &&
                                    <button
                                        type="button"
                                        className="btn btn-warning"
                                        disabled={props.working || props.cancelDisabled}
                                        onClick={props.cancelClick}
                                    >
                                        {props.cancelText ?? "Cancel"}
                                    </button>}
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
};

export interface OkCancelModalProps {
    open: boolean
    title: string,
    message: string,
    okText?: string
    apiUrl: string;
    afterAction: (confirm: boolean) => void;
}

export const ConfirmApiModal: (props: OkCancelModalProps) => JSX.Element = (props) => {

    const [state, setState] = useState<PageState>(PageState.Loaded);
    const [status, setStatus] = useState<PageStatus>(PageStatus.Idle);
    const { setError, errors } = useErrorStore();
    const { allErrorsBanner, statusBanner } = bannerHelper(state, status, { hideLoading: true });

    const children = <div>
        {statusBanner()}
        {allErrorsBanner(errors)}
        <div>{props.message}</div>
    </div>;

    const modalProps: ModalProps = {
        open: props.open,
        title: props.title,
        okText: props.okText ?? "Ok",
        okClick: () => callPostApi(props.apiUrl, {}, setState, setStatus, copyNewErrorsToOldForm(setError), () => props.afterAction(true)),
        cancelText: "Cancel",
        cancelClick: () => props.afterAction(false),
        iconVisible: true,
        working: status === PageStatus.Network,
        children: children
    };

    return <Modal {...modalProps} />;


};


export const DeleteConfirmModal: (props: { open: boolean, title: string, message: string, afterAction: (reloadNeeded: boolean) => void, deleteUrl: string }) => JSX.Element = (props) => {
    const [pageState, setPageState] = useState<PageState>(PageState.Loaded);
    const [pageStatus, setPageStatus] = useState<PageStatus>(PageStatus.Idle);
    const formObj = useForm<DeleteModel>();
    const { TextBoxRow } = formRows(formObj, pageStatus === PageStatus.Network);
    const { statusBanner } = bannerHelper(pageState, pageStatus, { hideLoading: true });

    const doSubmit = (model: DeleteModel) => callPostApi(props.deleteUrl, model, setPageState, setPageStatus, copyNewErrorsToOldForm(formObj.setError), () => props.afterAction(true));

    const children = <div>
        {statusBanner()}
        <div>{props.message}</div>
        <form id="delete-form" onSubmit={formObj.handleSubmit(doSubmit)}>
            {TextBoxRow({ title: "Enter the name to confirm", property: "name", required: true } as any)}
        </form>
    </div>;

    const modalProps = {
        open: props.open,
        title: props.title,
        okVisible: true,
        cancelVisible: true,
        okText: "Delete",
        okButtonFormId: "delete-form",
        okDisabled: pageStatus === PageStatus.Network,
        cancelText: "Cancel",
        cancelDisabled: pageStatus === PageStatus.Network,
        cancelClick: () => props.afterAction(false),
        iconVisible: true,
        working: pageStatus === PageStatus.Network,
        children
    };

    return <Modal {...modalProps} />;
};

export const DeleteSimpleModal: (props: { open: boolean, title: string, message: string, afterAction: (reloadNeeded: boolean) => void, deleteUrl: string }) => JSX.Element = (props) => {
    const [state, setState] = useState<PageState>(PageState.Loaded);
    const [status, setStatus] = useState<PageStatus>(PageStatus.Idle);
    const { setError, errors } = useErrorStore();
    const { allErrorsBanner, statusBanner } = bannerHelper(state, status, { hideLoading: true });

    const children = <div>
        {statusBanner()}
        {allErrorsBanner(errors)}
        <div>{props.message}</div>
    </div>;

    const modalProps: ModalProps = {
        open: props.open,
        title: props.title,
        okClick: () => callPostApi(props.deleteUrl, {}, setState, setStatus, copyNewErrorsToOldForm(setError), () => props.afterAction(true)),
        okText: "Delete",
        okButtonFormId: "delete-form",
        okDisabled: status === PageStatus.Network,
        cancelText: "Cancel",
        cancelDisabled: status === PageStatus.Network,
        cancelClick: () => props.afterAction(false),
        iconVisible: true,
        working: status === PageStatus.Network,
        children
    };

    return <Modal {...modalProps} />;
};

export const AddModal: (props: {apiUrl: string, title: string,  open: boolean, closeModal: (newGuid?: string) => void }) => JSX.Element = (props) => {
    const [pageState, setPageState] = useState<PageState>(PageState.Loaded);
    const [pageStatus, setPageStatus] = useState<PageStatus>(PageStatus.Idle);
    //const [newGuid, setNewGuid] = useState<string>("");
    const { errors, setError, clearErrors } = useErrorStore();
    const { statusBanner, allErrorsBanner } = bannerHelper(pageState, pageStatus, { hideLoading: true });
    const closeFunc = () => props.closeModal();

    // if (pageState === PageState.Saved) {
    //     props.closeModal(newGuid);
    // }

    //const setJson = (data: NewGuidModel) => setNewGuid(data.guid);

    const okFunc = () => {
        clearErrors();
        callPostApi(props.apiUrl, {}, setPageState, setPageStatus, copyNewErrorsToOldForm(setError), (d: GuidResponseModel) => props.closeModal(d.guid));
    };


    const children = <div>
        <div>Do you want to add a new {props.title}?</div>
        {statusBanner()}
        {allErrorsBanner(errors, { hideNames: true })}
    </div>;

    const modalProps = {
        open: props.open,
        title: `Add ${props.title}`,
        okVisible: true,
        cancelVisible: true,
        okText: "Add",
        okClick: okFunc,
        okDisabled: pageStatus === PageStatus.Network,
        cancelText: "Cancel",
        cancelDisabled: pageStatus === PageStatus.Network,
        cancelClick: closeFunc,
        iconVisible: true,
        working: pageStatus === PageStatus.Network,
        children
    };

    return <Modal {...modalProps} />;
};